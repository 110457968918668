<template>
	<div class="container">
		<div class="handle" style="display:flex;margin:10px 0;">
			<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				end-placeholder="结束日期" :shortcuts="shortcuts" @change="dateChange"></el-date-picker>
			<el-input placeholder="请输入标题" v-model="query.title" style="margin-left: 5px;width:30%;">
			</el-input>
			<el-select v-model="query.type" placeholder="请选择类型" style="margin:0 5px;">
				<el-option v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-button icon="el-icon-search" @click="goSearch"></el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			v-loading="tableLoading">
			<el-table-column prop="c_no" label="举报单号" align="center" width="220"></el-table-column>
			<el-table-column label="内容标题/描述">
				<template #default="scope">
					<div class="title">
						{{scope.row.source.title}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="author.nick_name" label="内容作者"></el-table-column>
			<el-table-column label="举报选项" align="center" width="100">
				<template #default="scope">
					<span v-if="scope.row.type == 0">内容违规</span>
					<span v-else-if="scope.row.type == 1">侵犯权益</span>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="举报日期" align="center" width="180"></el-table-column>
			<el-table-column prop="operator" label="审核人" v-if="type!=0"></el-table-column>
			<el-table-column prop="operation_time" label="审核时间" v-if="type!=0"></el-table-column>
			<el-table-column prop="remark" label="备注" v-if="type!=0"></el-table-column>
			<el-table-column label="操作" width="180" align="center" v-if="type==0">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="handle(scope.row)">操作</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog title="举报处理" v-model="showDetail" width="90%">
			<el-row :gutter="20" v-if="detail">
				<el-col :span="12">
					<div style="font-size: 24px;color: rgb(51, 51, 51);margin-bottom: 20px;">
						内容详情
					</div>
					<component v-if="showDetail" :is="currentTabComponent" :detail="detail.source"></component>
				</el-col>
				<el-col :span="12">
					<div style="font-size: 24px;color: rgb(51, 51, 51);margin-bottom: 20px;">
						处理
					</div>
					<div class="item-title">
						举报选项
					</div>
					<el-input v-model="detail.content" disabled></el-input>
					<div class="item-title" v-if="detail.original_link!=0">
						原创链接
					</div>
					<el-input v-model="detail.original_link" disabled v-if="detail.original_link!=0"></el-input>
					<div class="item-title" v-if="detail.name">
						投诉人主体信息
					</div>
					<div class="desc" v-if="detail.name" style="font-size: 20px;color: #7F7F7F;">
						{{detail.name}}
					</div>
					<div class="item-title" v-if="detail.contact!=0">
						投诉联系方式
					</div>
					<div class="desc" v-if="detail.contact!=0" style="font-size: 20px;color: #7F7F7F;">
						{{detail.contact}}
					</div>
					<div class="item-title" v-if="detail.desc">
						投诉描述
					</div>
					<div class="desc" v-if="detail.desc" style="font-size: 20px;color: #7F7F7F;">
						{{detail.desc}}
					</div>
					<div class="item-title" v-if="detail.testification">
						投诉证明材料
					</div>
					<div class="image-box" v-if="detail.testification">
						<el-image :src="filterCosImage(item)" v-for="(item,index) in detail.testification.split(',')"
							:key="index" class="image-view" fit="cover" />
					</div>
					<div class="bor">
						
					</div>
					<div class="item-title">
						审核结果
					</div>
					<el-select v-model="complain_query.status" placeholder="请选择">
						<el-option v-for="item in complain_options" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<div class="item-title" v-if="complain_query.status==1">
						处理操作
					</div>
					<el-select v-model="complain_query.dispose_type" placeholder="请选择" v-if="complain_query.status==1">
						<el-option v-for="item in handle_options" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<div class="item-title" v-if="complain_query.dispose_type==1&&complain_query.status==1">
						封号天数
					</div>
					<el-input placeholder="请输入天数" v-model="complain_query.stop_days" type="number" style="width: 217px;"
						v-if="complain_query.dispose_type==1&&complain_query.status==1" min="0" @input="numberInput">
						<template #append>天</template>
					</el-input>
					<div class="item-title">
						备注说明
					</div>
					<el-input placeholder="请输入" v-model="complain_query.mark" type="textarea"></el-input>
					<div class="handle-box">
						<el-button @click="sub">提交</el-button>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
	import {
		watch,
		reactive,
		ref
	} from 'vue'
	import PAGE_CONFIG from '@/utils/config'
	import request from '@/utils/request.js'
	import articleItem from '@/components/review/articleItem'
	import imageItem from '@/components/review/imageItem'
	import videoItem from '@/components/review/videoItem'
	import audioItem from '@/components/review/audioItem'
	import { ElMessage } from 'element-plus'
	import { filterCosImage } from '@/utils/common.js'
	export default {
		props: ['activeType', 'type'],
		components: {
			articleItem,
			imageItem,
			videoItem,
			audioItem
		},
		setup(props) {
			let query = reactive({
					status: props.type,
					page: 1,
					limit: 15,
					start_time: '',
					end_time: '',
					title: '',
					type: -1
				}),
				tableLoading = ref(false),
				tableData = ref([]),
				pageTotal = ref(0),
				date = ref([]),
				shortcuts = [{
					text: '最近一周',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						return [start, end]
					})()
				}, {
					text: '最近一个月',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						return [start, end]
					})()
				}, {
					text: '最近三个月',
					value: (() => {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						return [start, end]
					})()
				}],
				type_options = [{
						label: '全部',
						value: -1
					}, {
						label: '内容违规',
						value: 0
					},
					{
						label: '侵犯权益',
						value: 1
					}
				],
				showDetail = ref(false),
				currentTabComponent = ref(''),
				detail = ref(''),
				complain_options = [{
					label: '举报失效',
					value: 0
				}, {
					label: '举报成功',
					value: 1
				}, ],
				complain_query = reactive({
					status: 0,
					dispose_type: 0,
					stop_days: 0,
					mark: ''
				}),
				handle_options = [{
						label: '删除内容',
						value: 0
					},
					{
						label: '删除内容并有限期封号',
						value: 1
					},
					{
						label: '删除内容并永久封号',
						value: 2
					}
				]
			let getData = () => {
				// tableLoading.value = true
				request({
					url: 'admin/complain/lists',
					method: 'post',
					data: {
						...query
					},
				}).then(({code,data:{data,total}}) => {
					tableLoading.value = false
					console.log(code)
					if (code == 200) {
						// console.log(data)
						tableData.value = data;
							console.log(tableData)
						pageTotal.value = total;
					}
				}).catch(err => {
					tableLoading.value = false;
					console.log(err)
				});
			}
			let goSearch = () => {
				query.page = 1
				getData()
			}
			let handlePageChange = (val) => {
				query.page = val
				getData()
			}
			let handle = (item) => {
				detail.value = ''
				switch (item.source.type) {
					case 0:
						currentTabComponent.value = 'image-Item'
						break;
					case 1:
						currentTabComponent.value = 'article-Item'
						break;
					case 2:
						currentTabComponent.value = 'audio-Item'
						break;
					case 3:
						currentTabComponent.value = 'video-Item'
						break;
				}
				complain_query.id = item.id
				getDetail(item.id)
			}
			const getDetail = (id)=> {
				request({
					url: '/admin/complain/info',
					method: 'get',
					params: {
						id
					},
				}).then(({data,code}) => {
					if (code == 200) {
						if(data.source.type==0) {
							data.source.content=data.source.content.split(',')
						}
						detail.value = data
						showDetail.value = true
					}
				}).catch(err => {
					console.log(err)
				});
			}
			let dateChange = (e) => {
				if (e) {
					query.start_time = e[0].getTime() / 1000
					query.end_time = e[1].getTime() / 1000
				} else {
					query.start_time = ''
					query.end_time = ''
				}
			}
			let sub = ()=> {
				if(complain_query.dispose_type==1&&complain_query.stop_days<1){
					ElMessage.error("请输入正确的封禁天数")
					return
				}
				request({
					url: 'admin/complain/check',
					method: 'post',
					data: {
						...complain_query
					},
				}).then((res) => {
					if (res.code == 200) {
						getData()
						showDetail.value = false
					}
				}).catch(err => {
					console.log(err)
				});
			}
			const numberInput = (val)=> {
				complain_query.stop_days = val.replace(/[^0-9]/ig,"");
			}
			getData()
			watch(
				() => props.activeType,
				(val) => {
					if (val == props.type) {
						getData()
					}
				}

			)
			watch (
				() => showDetail.value,
				(val) => {
					if (!val) {
						complain_query.status = 0
						complain_query.dispose_type = 0
						complain_query.stop_days = 0
						complain_query.mark = ''
					}
				}
			)
			return {
				query,
				tableLoading,
				tableData,
				pageTotal,
				date,
				shortcuts,
				dateChange,
				type_options,
				goSearch,
				handle,
				handlePageChange,
				showDetail,
				currentTabComponent,
				detail,
				defaultUrl: PAGE_CONFIG.defaultUrl,
				complain_options,
				complain_query,
				handle_options,
				sub,
				getDetail,
				numberInput,
				filterCosImage:filterCosImage
			}
		}
	}
</script>

<style scoped>
	.title {
		width: 100%;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.image-box {
		display: flex;
		flex-wrap: wrap;
	}

	.image-view {
		width: 92px;
		height: 92px;
	}
	.bor {
		height: 5px;
		width: 100%;
		background: #e1e1e1;
		margin-top: 30px;
	}
	.item-title {
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		color: #555555;
	}
	.handle-box {
		width: 100%;
		padding: 30px 0;
		display: flex;
		justify-content: center;
	}
</style>
