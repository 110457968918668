<template>
    <div>
        <h2>标题：{{detail.title}}</h2>
		<h3 style="margin: 15px 0;">分类：{{detail.cate_name}}</h3>
        <div class="img-box" v-if="detail.m_type != 1">
            <el-image :src="filterCosImage(item)" v-for="(item,index) in detail.content" :preview-src-list="srcList" :key="index" class="image-view" fit="cover"/>
        </div>
		<div v-if="detail.m_type == 1 && video_url">
			<video :poster="filterCosImage(detail.cover)" controls style="width:300px;display:block;">
				<source :src="defaultUrl+detail.content[0]" type="video/mp4">
			</video>
		</div>
    </div>
</template>

<script>
import PAGE_CONFIG from '@/utils/config'
import { filterCosImage } from '@/utils/common.js'
export default {
    props: ['detail'],
    data() {
        return {
            defaultUrl:PAGE_CONFIG.defaultUrl,
			srcList: [],
			video_url:true
        }
    },
	watch:{
		detail(){
			if(this.detail.m_type !=1) {
				for(let i in this.detail.content) {
					this.srcList.push(this.filterCosImage(this.detail.content[i]))
				}
			}
		}
	},
	methods:{
		filterCosImage
	},
	beforeUnmount(){
		this.video_url = ''
	},
	mounted() {
		for(let i in this.detail.content) {
			this.srcList.push(this.defaultUrl+this.detail.content[i])
		}
	}
}
</script>

<style>
	.img-box {
		display: flex;
		flex-wrap: wrap;
	}
	.image-view {
		width: 150px;
		height: 150px;
		border-radius: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
</style>