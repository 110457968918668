<template>
	<div class="video-page">
		<h2>标题：{{detail.title}}</h2>
		<h3 style="margin: 15px 0;">分类：{{detail.cate_name}}</h3>
		<div v-if="video_url">
			<video :poster="filterCosImage(detail.cover)" controls style="width:300px;display:block;">
				<source :src="defaultUrl+detail.content" type="video/mp4">
			</video>
		</div>
	</div>
</template>

<script>
	import PAGE_CONFIG from '@/utils/config'
	import { filterCosImage } from '@/utils/common.js'
	export default {
		props: ['detail'],
		data() {
			return {
				defaultUrl: PAGE_CONFIG.defaultUrl,
				video_url:true
			}
		},
		beforeUnmount(){
			this.video_url = ''
		},
		watch:{
			detail(val){
				console.log(val)
			},
		},
		methods:{
			filterCosImage
		}
	}
</script>
<style lang="scss" scoped>
// .video-page {
// 	// width: 300px;

// }
</style>