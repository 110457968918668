<template>
    <div>
        <h2>标题：{{detail.title}}</h2>
		<h3 style="margin: 15px 0;">分类：{{detail.cate_name}}</h3>
		<div class="cover-box" style="margin: 15px 0;">
			<h3>封面：</h3>
			<el-image :src="filterCosImage(detail.cover)" :preview-src-list="[filterCosImage(detail.cover)]" class="image-view" fit="cover" />
		</div>
        <div>
            <audio :src="defaultUrl+detail.content" controls style="width:100%;display:block;"></audio>
        </div>
    </div>
</template>

<script>
import PAGE_CONFIG from '@/utils/config'
import { filterCosImage } from '@/utils/common.js'
export default {
    props: ['detail'],
    data() {
        return {
            defaultUrl:PAGE_CONFIG.defaultUrl
        }
    },
    methods:{
        filterCosImage
    }
}
</script>

<style lang="scss" scoped="scoped">
	
	.cover-box {
		display: flex;
	}

	.image-view {
		width: 300px;
	}
</style>